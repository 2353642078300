import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Login from './auth/Login'
import Home from './homepage/Home'
import Appbar from './appbar/Appbar';
import StickyFooter from './footer/Footer';
import Sessions from './sessions/Sessions';
import Info from './info/Info'
import RedirectHome from './components/Redirect';
//import Balance from './balance/balance';

function App() {
  return (
    <Router>
      <div className='App'>
        <RedirectHome />
        <Appbar />
        <Routes>
          <Route exact path='/' element={<Navigate to={'/login'} />} />
          <Route exact path='/inicio' element={<Home />} />
          <Route exact path='/login' element={<Login />} />
          <Route exact path='/sesion' element={<Sessions />} />
          <Route exact path='/info' element={<Info />} />
        </Routes>
      </div>
      <footer>
        <StickyFooter />
      </footer>
    </Router>
  );
}

export default App;
