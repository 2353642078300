import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';

import { ip_host,port } from '../variables';

const url_signup = "http://" + ip_host + ":"+ port + "/api/login/"

const defaultTheme = createTheme();


export default function SignIn() {

    const [dpi, setDpi] = React.useState(null);
    const [password, setPassword] = React.useState(null);
    const navigate = useNavigate();
    

    React.useEffect(() => {
        if (window.localStorage.getItem("session") != null) {
            var conexion = JSON.parse(window.localStorage.getItem("session"));
            if (conexion.conectado === true) {
                navigate('/inicio');
            }
        }
        
    }, [navigate]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (dpi === null || password === null || dpi === "" || password === "") {
            window.alert("Llena todos los campos")
        }
        else {
            var data = JSON.stringify({
                userID: dpi,
                password: password
            });
            await axios.post(
                url_signup, data,
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                    }
                }
            ).then(response => {

                //console.log(response.data.data)
                window.localStorage.setItem('data', JSON.stringify(response.data.data));
                var session = {
                    conectado: true
                }
                window.localStorage.setItem('session', JSON.stringify(session))

                window.location.replace('/Inicio');
            }).catch(error => {
                if (error.response) {
                    // El servidor respondió con un código de estado que no está en el rango 2xx
                    //console.error('Error de respuesta del servidor:', error.response.data);
                    console.error('Código de estado:', error.response.status);
                    window.alert("Error: Usuario o Contraseña incorrecta")
                } else if (error.request) {
                    // La solicitud fue realizada pero no se recibió respuesta del servidor
                    console.error('No se recibió respuesta del servidor:', error.request);
                    window.alert("Error: Intentelo nuevamente")
                } else {
                    // Ocurrió un error al configurar la solicitud
                    console.error('Error al configurar la solicitud:', error.message);
                    window.alert("Error: Intentelo nuevamente")
                }
            });
        }
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs" id='container'>
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 5,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img src={process.env.PUBLIC_URL + '/Kingo.png'} alt="logo" className='kingo-header' />
                    <Divider />
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            onChange={e => setDpi(e.target.value)}
                            type="number"
                            inputMode='numeric'
                            inputProps={{ min: 0 }}
                            id="dpi"
                            label="DPI"
                            name="dpi"
                            autoComplete="dpi"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            onChange={e => setPassword(e.target.value)}
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Log In
                        </Button>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}