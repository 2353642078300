import axios from "axios";
import { ip_host,port } from '../variables';

export const UpdateData = async () => {

    const url_user = "http://" + ip_host + ":"+ port + "/api/data/"

    var localdata = JSON.parse(window.localStorage.getItem("data"));

    var data = {
        token: localdata.token
    }
    var response = null;
    
    response = await axios.post(
        url_user,data,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            }
        }
    ).catch(error => {
        console.log(error)
        return null
    });
    return response.data.data
    
}