
const Enviroment = "Dev"

var ip_host = window.location.hostname

if(Enviroment === "Docker"){
    ip_host = "172.17.0.1"
}

const port = "8000"

export{ ip_host,port}