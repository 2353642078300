import { AppBar } from '@mui/material';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

import Wifi  from '@mui/icons-material/Wifi';
import Menu from './Menu';
import logOut from '../auth/Logout';

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#fe5000',
        },
        secondary: {
            main: '#888b8d',
        },
    },
});




function Appbar() {

    var conexion = false;
    const data = JSON.parse(localStorage.getItem('session'));
    if(data != null){
        conexion = data.conectado
    }

    return (
        <div>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static" theme={theme}>
                    <Toolbar>
                        {conexion === true &&
                            <Menu/>
                        }
                        <Wifi sx={{fontSize:'2.0rem', marginRight:1}} />
                        <Typography variant="h4" component="div" sx={{ flexGrow: 1, textAlign:'left' }}>
                            KingoNet
                        </Typography>
                        {conexion === true &&
                            <Link to='/home'>
                                <Button variant='contained' color="inherit" theme={theme} sx={{  flexGrow:1,justifyContent:"flex-end",color:'white',backgroundColor:'#ff6219' }} onClick={logOut}>Log out</Button>
                            </Link>
                        }
                        {conexion === false &&
                            <div>
                                <Link to='/login'>
                                    <Button variant='contained' color="inherit" theme={theme} sx={{  flexGrow:1,justifyContent:"flex-end", color:'white',backgroundColor:'#ff6219' }}>Log in</Button>
                                </Link>
                            </div>
                        }
                    </Toolbar>
                </AppBar>
            </Box>
        </div>
    );
}

export default Appbar;