import React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import axios from 'axios';
import { UpdateData } from '../functions/updateData';
import { ip_host,port } from '../variables';


const url_session = "http://" + ip_host + ":"+ port + "/api/session/"

export default function Sessions() {

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSessionData(null);

        if (clientID === null || tarifa === null || clientID === "" || tarifa === "") {

        }
        else {
            if (!(clientID.length === 8 || clientID.length === 13)) {
                window.alert("Ingresa un numero de DPI o teléfono válido")
            }
            else {
                var payload = {
                    token: data.token,
                    clientID: clientID,
                    tarifa: tarifa
                }
                console.log(data)
                await axios.post(
                    url_session, payload,
                    {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            'Content-Type': 'application/json',
                        }
                    }
                ).then(response => {
                    setSessionData(response.data.password);
                    data.balance = response.data.balance
                    window.localStorage.setItem('data', JSON.stringify(data))
                }).catch(error => {
                    if (error.response) {
                        // El servidor respondió con un código de estado que no está en el rango 2xx
                        //console.error('Error de respuesta del servidor:', error.response.data);
                        console.error('Código de estado:', error.response.status);
                        window.alert("Error: " + String(error.response.data.status))
                    } else if (error.request) {
                        // La solicitud fue realizada pero no se recibió respuesta del servidor
                        console.error('No se recibió respuesta del servidor:', error.request);
                        window.alert("Error: Intentelo nuevamente")
                    } else {
                        // Ocurrió un error al configurar la solicitud
                        console.error('Error al configurar la solicitud:', error.message);
                        window.alert("Error: Intentelo nuevamente")
                    }
                });
            }
        }



    }


    const [data, setData] = React.useState([""])

    const [clientID, setClientID] = React.useState(null);
    const [tarifa, setTarifa] = React.useState('')

    const [sesionData, setSessionData] = React.useState(null)



    React.useEffect(() => {
        const handleUpdate = async () => {
            try {
                var update_data = await UpdateData()
                console.log(update_data)
                setData(update_data);
            } catch (error) {
                console.error('Error', error)
            }
        }
        handleUpdate();

    }, []);

    return (
        <Container component="main" maxWidth="xs" id="container" >
            <CssBaseline />
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <Typography component="p" variant="h4" sx={{marginBottom:2}}>
                    Vender Internet
                </Typography>
                <React.Fragment>
                    <Typography component="h2" variant='h5'>ID: {data.userID}</Typography>
                    <Typography component="h2" variant='h5'>KingoNet #{data.kingonetID}</Typography>
                    <Typography component="h2" variant='h5'>Comunidad: {data.community}</Typography>
                    <Typography component="h2" variant='h5'>Tienda: {data.storeName}</Typography>
                    <Typography component="h2" variant="h5" color="primary" gutterBottom>
                        Saldo actual:  Q.{data.balance}
                    </Typography>
                    <Typography component="p" variant="h4">

                    </Typography>
                </React.Fragment>

                <TextField
                    margin="normal"
                    required
                    fullWidth
                    onChange={e => setClientID(e.target.value)}
                    type="number"
                    inputMode='numeric'
                    inputProps={{ min: 0 }}
                    id="clientID"
                    label="DPI o Teléfono"
                    name="clientID"
                    autoComplete="clientID"
                    autoFocus
                />
                <FormControl fullWidth sx={{ mt: 1 }}>
                    <InputLabel id="demo-simple-select-helper-label" >Tiempo de sesión</InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={tarifa}
                        defaultValue=''
                        label="Tiempo de sesión"
                        onChange={(e) => setTarifa(e.target.value)}
                    >
                        <MenuItem value='1hora'>1 Hora</MenuItem>
                        <MenuItem value='1D'>1 Dia</MenuItem>
                        <MenuItem value='3D'>3 Dias</MenuItem>
                        <MenuItem value='7D'>7 Dias</MenuItem>
                    </Select>
                </FormControl>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Vender Internet
                </Button>
                {sesionData !== null &&
                    <div>
                        <Typography component="p" variant="h5">
                            Usuario Creado Existosamente
                        </Typography>
                        <Typography component="p" variant="h6">
                            La contraseña es: {sesionData}
                        </Typography>
                    </div>

                }
            </Box>
        </Container>
    );
}