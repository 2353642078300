import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Link } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { createTheme } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Typography } from '@mui/material';


export default function Menu() {

    const theme = createTheme({
        palette: {
            primary: {
                main: '#fe5000',
            },
            secondary: {
                main: '#888b8d',
            },
        },
    });

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            bgcolor='#ff6219'
            sx={{  color:'white', height: '100%', width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 200 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <IconButton  sx={{color:'white',fontSize:30,}} onClick={toggleDrawer(anchor, false)}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                Back
            </IconButton>
            <Divider />
            <List sx={{color:'white',fontSize:'2rem'}}   >
                {['Inicio', 'Sesion', 'Info'].map((text, index) => (
                    <ListItem button key={text}  component={Link} to={'/' + text} >
                        <Typography id='menu_list'>{text}</Typography>
                    </ListItem>
                ))}
            </List>
        </Box>
    );




    return (
        <div id='menu'  >
            {['left'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Button
                        size="large"
                        variant='text'
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={toggleDrawer(anchor, true)}>Menu</Button>
                    <SwipeableDrawer

                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                    >
                        {list(anchor)}
                    </SwipeableDrawer>
                </React.Fragment>
            ))}
        </div>
    );

}