import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

export default function MediaCard(props) {
  return (
    <Card  id="card" onClick={()=> window.location.replace(props.path)} sx={{minWidth:250,margin:3}}>
      <CardMedia
        component="img"
        height="175"
        image={props.imagen}
        alt="imagen"
      />
      <CardContent id='card_content'>
        <Typography gutterBottom variant="h5" component="div">
          {props.titulo}
        </Typography>
        <Typography variant="body2" color="text.secondary" id="columnasTexto">
          {props.texto}
        </Typography>
      </CardContent>
    </Card>
  );
}