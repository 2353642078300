import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';

import axios from 'axios';

import { ip_host, port } from '../variables';

const url_profile = "http://" + ip_host + ":" + port + "/api/profile/"
const url_session = "http://" + ip_host + ":" + port + "/api/active/"


const profileColumns = [
    { field: 'user', headerName: 'Usuario', width: 150, align: 'center', headerAlign: 'center' },
    { field: 'password', headerName: 'Contraseña', width: 125, align: 'center', headerAlign: 'center' },
    { field: 'profile', headerName: 'Perfil', width: 100, align: 'center', headerAlign: 'center' },
];

const activeColumns = [
    { field: 'user', headerName: 'Usuario', width: 150, align: 'center', headerAlign: 'center' },
    { field: 'uptime', headerName: 'Tiempo Activo', width: 125, align: 'center', headerAlign: 'center' },
    { field: 'timeLeft', headerName: 'Tiempo restante', width: 125, align: 'center', headerAlign: 'center' },
    { field: 'bytesIn', headerName: 'Bytes In', width: 125, align: 'center', headerAlign: 'center' },
    { field: 'bytesOut', headerName: 'Bytes Out', width: 125, align: 'center', headerAlign: 'center' },
];

export default function Info() {


    const [profile, setProfile] = React.useState(null)
    const [active, setActive] = React.useState(null)



    React.useEffect(() => {

        const data = JSON.parse(window.localStorage.getItem("data"))
        const auth_token = JSON.stringify({
            token: data.token
        });


        const getProfiles = async () => {
            try {
                await axios.post(url_profile, auth_token,
                    {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            'Content-Type': 'application/json',
                        }
                    }).then(response => {
                        console.log(response.data.data)
                        setProfile(response.data.data)
                    });
            } catch (error) {
                console.error('Error', error)
            }
        }

        const getActives = async () => {
            try {
                axios.post(url_session, auth_token,
                    {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            'Content-Type': 'application/json',
                        }
                    }).then(response => {
                        console.log(response.data.data)
                        setActive(response.data.data)
                    });
            } catch (error) {
                console.error('Error', error)
            }
        }
        getProfiles()
        getActives()
    }, []);

    return (
        <div className='info'>
            {profile !== null &&
                <Container component="main" id="container-info" sx={{ width: 'fit-content', minWidth: 300}} >
                    <Typography variant="h6" id="tableTitle" component="div">
                        Usuarios
                    </Typography>
                    <DataGrid
                        rows={profile}
                        columns={profileColumns}
                        pageSizeOptions={[10]}
                        pageSize={[10]}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                            sorting: {
                                sortModel: [{ field: 'user', sort: 'asc' }],
                            },
                        }}


                        disableRowSelectionOnClick
                        getRowId={(row) => row.user}
                    />
                </Container>
            }
            {active !== null &&
                <Container component="main" id="container-info" sx={{ width: 'fit-content', minWidth: 300 }} >

                    <Typography variant="h6" id="tableTitle" component="div">
                        Sesiones Activas
                    </Typography>
                    <DataGrid
                        rows={active}
                        columns={activeColumns}
                        pageSizeOptions={[10]}
                        pageSize={[10]}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                            sorting: {
                                sortModel: [{ field: 'user', sort: 'asc' }],
                            },
                        }}
                        disableRowSelectionOnClick
                        getRowId={(row) => row.user}
                    />
                </Container>
            }
        </div>
    );
}

