import * as React from 'react';
import { useNavigate } from 'react-router-dom';



export default function RedirectHome() {

    const navigate = useNavigate();

    React.useEffect(() => {

        var path = String(window.location.pathname).toLocaleLowerCase();

        if (path !== "/login") {
            if (window.localStorage.getItem("session") != null) {
                var conexion = JSON.parse(window.localStorage.getItem("session"));
                if (conexion.conectado === true) {

                } else {

                    navigate('/login');
                }
            } else {
                var anonimo = {
                    conectado: false
                }
                window.localStorage.setItem('session', JSON.stringify(anonimo));

                navigate('/login');

            }
        }
    }, [navigate]);

    return (
        <div className='hidden'>
        </div>
    );
}