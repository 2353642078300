import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';

import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import MediaCard from './card';

const theme = createTheme();

export default function Home() {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Container component="main" id="home">
          <CssBaseline />
          <div className="form2" id="divHome">
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap:'wrap',
                  alignItems: 'center',
                  justifyContent:'center'
                }}
              >
                <MediaCard imagen="/images/Kingo.png" titulo="Vender Internet" texto="Ingresa para consultar tu saldo y vender internet" path="/sesion" />
                <MediaCard imagen="/images/Kingo.png" titulo="Añadir Fondos" texto="Ingresa para añadir fondos" path="/saldo" />
                <MediaCard imagen="/images/Kingo.png" titulo="Información" texto="Ingresa para consultar sobre las sesiones activas" path="/info" />
                <MediaCard imagen="/images/Kingo.png" titulo="Administración" texto="Ingresa para gestionar los usuarios de la plataforma" path="/admin" />
              </Box>
          </div>
        </Container>
      </ThemeProvider>
    </div>
  );
}